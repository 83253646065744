import React, {useState} from 'react';
import axios from 'axios';
import "./contactUs.css";
import Loader from '../../../components/loader/Loader';

function ContactUs() {
    const [uData, setUData] = useState({order: "", name: "", email: "", phone: "", });
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const sendForm =  async (e) =>{
        e.preventDefault();
        setIsLoading(true);
        setError("");
        const sData = uData;
        sData["subject"] = "Inquiry from Website by User with Name "+uData.name;
        try {
            const ans = await axios.post("https://api.walls.co.tz/mail/inquiry", sData);
            console.log(ans);
        } catch (error) {
            setError("Network error")
        }
        e.target.reset();
        setIsLoading(false);
        
    }

    const saveInput =(key, val) =>{
        const xData = uData;
        xData[key] = val;
        setUData(xData);
    }
    return (
        <div className="contactUsMain">
            <div className="contactUsContent">
                <div className="ContactUsContentLeft">
                    <div className="contactUsHead">
                        Let's Connect
                    </div>
                    <div className="contactUsHeadMega">
                        Reach Out to Start Your Journey,
                    </div>
                    <div className="contactUsPara">
                        We're here to help you achieve your goals. Whether you have a question, need more information, or are ready to get started, our team is just a click away. Let's discuss how we can bring your vision to life.
                    </div>
                </div>
                <div className="ContactUsContentRight">
                    <div className="ContactUsContentFormHolder">
                        <form onSubmit={(e) => sendForm(e)}>
                            <input className="inputContactUs" required type="text" placeholder="Your Name" name="name" onInput={(e) => saveInput("name", e.currentTarget.value)} />
                            <input className="inputContactUs" required type="email" placeholder="Your Email" name="email" onInput={(e) => saveInput("email", e.currentTarget.value)} />
                            <input className="inputContactUs" required type="tel" placeholder="Phone number" name="phone" onInput={(e) => saveInput("phone", e.currentTarget.value)} />
                            <textarea rows={5} className="textAreaContactUs" required placeholder="Message" onInput={(e) => saveInput("order", e.currentTarget.value)}></textarea>
                            <div className="error" style={error === "" ? {display: "none"} : {}}>
                                {
                                    error
                                }
                            </div>
                            <button type="submit" className="contactUsSendButton" style={isLoading ? {display : "none"} : {}}>
                                Submit Now
                            </button>
                            <div style={!isLoading ? {display : "none"} : {}}>
                                <Loader/>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactUs;
