import React from 'react';
import PropTypes from 'prop-types';
import "./teamPassportBox.css";


function TeamPassportBox({image, name, rank}) {
    return (
        <div className="TeamPassportBoxMain">
            <div className="TeamPassportImageHolder">
                <img src={image} alt={name} className="TeamPassportImage" />
            </div>
            <div className="teampassportRankHolder">
                <div className="teampassportName">
                    {
                        name
                    }
                </div>
                <div className="teampassportRank">
                    {
                        rank
                    }
                </div>
            </div>
        </div>
    );
}

TeamPassportBox.propTypes = {
    image: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    rank: PropTypes.string.isRequired,
};

export default TeamPassportBox;
