import React from 'react';
import "./coreValues.css";
import wallsMono from "../../../Images/walls-monochrome.svg";
import CoreValueBox from './CoreValuesBoxes/CoreValueBox';
import unionArt from '../../../Images/Union.svg';

function CoreValues() {
    return (
        <div className="CoreValuesMain">
            <div className="coreValueBgArtHolder" style={{backgroundImage: "url("+wallsMono+")"}} />
            <div className="coreValueBgArtHolderRight" style={{backgroundImage: "url("+wallsMono+")"}} />
            <div className="CoreValuesBoxContentHolder">
                <div className="CoreValuesHeaderBox">
                    <div className="coreValueMainThinHeader">OUR CORE VALUES</div>
                    <div className="coreValueMagaBoldHeader">The Principles We Stand By</div>
                    <div className="coreValueBigThinHeader">What Drives Us Forward!</div>
                </div>
            </div>
            <div className="coreValuesBoxesHolder">
                <div className="coreValueBoxesHolderTopRow">
                    <CoreValueBox
                        number={1}
                        head="Integrity"
                        description="We believe that trust is the foundation of strong relationships. Integrity guides everything we do, from how we interact with our clients to how we handle their cargo."
                        borderRadius="50px 50px 50px 0"
                    />
                    <div className="CoreValueRowSpacer" />
                    <CoreValueBox
                        number={3}
                        head="Safety"
                        description="We prioritize the wellbeing of our staff, clients, and cargo by adhering to strict safety protocols in every aspect of our services."
                        borderRadius="50px 50px 50px 0"
                    />
                </div>
                <div className="coreValuesUnionHolder">
                    <img src={unionArt} className="coreValuesUnionImg" alt="Art" />
                </div>
                <div className="coreValueBoxesHolderLowerRow">
                    
                    <CoreValueBox
                        number={2}
                        head="Excellence"
                        description="Our team of experienced professionals is dedicated to delivering the highest quality of service, using state-of-the-art technology and best practices to ensure efficiency and accuracy."
                        borderRadius="0px 50px 50px 50px"
                    />
                    <div className="CoreValueRowSpacer" />
                    <CoreValueBox
                        number={4}
                        head="Customer Focus"
                        description="Our customers are at the heart of our business. We listen to their needs, understand their challenges, and tailor our services to meet their unique requirements."
                        borderRadius="0px 50px 50px 50px"
                    />
                </div>
            </div>
        </div>
    );
}

export default CoreValues;
