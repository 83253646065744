import React from 'react';
import { useNavigate } from 'react-router-dom';
import './homeService.css';
import ServiceBoxHome from './ServiceBoxHome/ServiceBoxHome';

function HomeServices() {
    const navigate = useNavigate();
    return (
        <div className="HomeServicesMain">
            <div className="HomeServiceShapeBg"/>
            <div className="HomeServiceUpperRow">
                <ServiceBoxHome
                    icon={"delete_sweep"}
                    head="Marine Waste Management"
                    description={"We offer comprehensive waste management services, ensuring the proper segregation and disposal of all types of waste. This include chemicals handling, expired consignments disposal as well as garbage disposal."}
                />
                <ServiceBoxHome
                    icon={"123"}
                    head="Cargo Tallying, Inspection & Survey "
                    description={"We provide advanced cargo tallying and inspection services, ensuring accuracy and compliance with international standards."}
                />
                <ServiceBoxHome
                    icon={"mfg_nest_yale_lock"}
                    head="Lashing & Unlashing Services"
                    description={"Our team of trained experts ensures that cargo is securely lashed and unlashed using high-quality equipment, adhering to strict safety protocols for smooth and efficient operations."}
                />
                <ServiceBoxHome
                    icon={"local_gas_station"}
                    head="Bunkering"
                    description={"We provide safe and efficient bunkering services, adhering to safety measures that prevent spills, contamination, and other risks."}
                />
            </div>
            <div className="HomeServiceLowerRow">
                <ServiceBoxHome
                    icon={"manufacturing"}
                    head="Mechanical Services and Supplies"
                    description={"Being authorized dealers for BOSCH we belong to an affiliation of very advanced German mechanical technology, whereas we have highly trained personnel in electromechanical as well as mechatronics services."}
                />
                <ServiceBoxHome
                    icon={"propane"}
                    head="Bulky Fuel Supply Services"
                    description={"We have been suppliers of fuel for various projects ranging from construction, industrial, agricultural, fishing to mining projects, the business which has gone hand in hand with our owned fuel stations operations countrywide."}
                />
                <div className="HomeServicesTextDetails">
                    <div className="HomeServicesTextDetailsHead">WHAT WE DO</div>
                    <div className="HomeServicesTextDetailsMagaHead">Discover What We Can Do</div>
                    <div className="HomeServicesTextDetailsHead4You">For You!</div>
                    <div className="HomeServicesTextDetailsActionHolder">
                        <button className="HomeServicesTextDetailsAction" type="button" onClick={()=> navigate("/services")}>Explore All</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomeServices;
