import React from 'react';
import "./ourProducts.css";
import inspection from "../../../Images/inspection_tool.png";
import client from "../../../Images/test_view_tool.png";

function OurProducts() {
    return (
        <div className="OurProductsMain">
            <div className="OurProductsHeaderContHolder">
                <div className="OurProductsHeaderIntro">Our Innovative Solutions</div>
                <div className="OurProductsHeaderMain">
                    Leading the Way with Cutting-Edge Technology, First of Its Kind for You.
                </div>
            </div>
            <div className="OurProductsContentHolder">
                <div className="ourProductsContentLeft">
                    <div className="ourProductsContentImgHolder">
                        <img src={inspection} className="ourProductsContentImg" alt="walls inspection tool" />
                    </div>
                    <div className="ourProductsContentProductName">
                        Inspection Tool
                    </div>
                    <div className="ourProductsContentProductDescription">
                        Our Inspection Tool streamlines the vehicle inspection and discharge processes, enabling our employees to conduct precise and efficient inspections on-site. This technology ensures that every step, from unloading to safety checks, is thoroughly documented with real-time data, reducing the risk of human error and improving overall operational efficiency.
                    </div>
                </div>
                <div className="ourProductsContentRight">
                    <div className="ourProductsContentImgHolder">
                        <img src={client} className="ourProductsContentImg" alt="walls inspection tool" />
                    </div>
                    <div className="ourProductsContentProductName">
                        Client Tool
                    </div>
                    <div className="ourProductsContentProductDescription">
                        Our Client Tool offers real-time visibility into the progress of your work, providing you with instant updates on every phase of the cargo management process. Whether it’s monitoring vehicle discharge, tallying, or fumigation, this tool allows you to track your cargo in real-time, ensuring transparency and peace of mind.
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OurProducts;
