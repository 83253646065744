import React, {useState} from 'react';
import './callAction.css';
import callAction from "../../../Images/home.png";
import ship from "../../../Images/battleship.svg";
import Inquiry from '../../../components/Iquiry/Inquiry';

function CallAction() {
    const [popup, setPopup] = useState(false);
    return (
        <div className="callActionMain">
            <div className="callActionImgBg" style={{backgroundImage: "url("+callAction+")"}}>
                <div className="shipIconHolderCallActionHome">
                    <img src={ship} alt="ship icon" className="callToActionShipIcon" />
                </div>

                <div className="callActionMessageHomeHolder">
                    <div className="callActionMessageHomeHolderHalf">
                        <div className="callActionMessageHome" >
                            With a team of experienced professionals and advanced technology, we provide a comprehensive range of services from cargo tallying and inspection to fumigation, bunkering, and HSE compliance. Our focus on safety, customer satisfaction, and innovation sets us apart as your trusted partner in the maritime industry. If you’re interested in learning more or working with us, please click the inquiry button below to get started.
                        </div>
                    </div>
                    <div className="callActionEquariesActionHolder">
                        <button className="callActionEquariesAction" type="button" onClick={()=> setPopup(true)}>Send Inquiries</button>
                    </div>
                </div>
                <div className="popupAnyPageHolder" style={ !popup ? {display: "none"} : {}}>
                    <button className="popupCloseButton" onClick={()=> setPopup(false)}>
                        <span className="material-symbols-outlined">close</span>
                    </button>
                    <Inquiry/>
                </div>
            </div>
        </div>
    );
}

export default CallAction;
