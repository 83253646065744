import React from 'react';
import PropTypes from 'prop-types';
import "./serviceRow.css";


function ServiceRow({head, description, image, isLeft}) {
    return (
        <div className="ServiceRowMain">
            <div className="ServiceImageAndDescrRow" style={isLeft ? {} : {flexDirection: "row-reverse"}}>
                <div className="ServiceImage" style={{backgroundImage: "url('"+image+"')"}} />
                <div className="serviceDescription">
                    <h3 className="ServiceRowHead">
                        {
                            head
                        }
                    </h3>
                    <p className="ServiceRowPara">
                        {
                            description
                        }
                    </p>
                </div>
            </div>
        </div>
    );
}

ServiceRow.propTypes = {
    head: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    isLeft: PropTypes.bool.isRequired,
};

export default ServiceRow;
