import React from 'react';
import "./homePage.css";
import CallAction from './CallAction/CallAction';
import HomeServices from './HomeServices/HomeServices';
import CoreValues from './CoreValues/CoreValues';
// import Testmonials from './Testmonials/Testmonials';
import Projects from './Projects/Projects';
import OurProducts from './OurProducts/OurProducts';
import ContactUs from './ContactUs/ContactUs';

function HomePage() {
    return (
        <div className="homePage">
            <div className="callToActionHolder">
                <CallAction />
            </div>
            <div className="sectionHolder">
                <HomeServices />
            </div>
            <div className="sectionHolder">
                <CoreValues />
            </div>
            <div className="sectionHolder">
                <Projects />
            </div>
            <div className="sectionHolder">
                <OurProducts />
            </div>
            {/* <div className="sectionHolder" style={{display: "none"}}>
                <Testmonials />
            </div> */}
            <div className="sectionHolder">
                <ContactUs />
            </div>
        </div>
    );
}

export default HomePage;
