import React from 'react';
import { Outlet } from 'react-router-dom';
import "./mainApp.css";
import TopNav from '../components/TopNav/TopNav';
import Footer from '../components/Footer/Footer';

function MainApp(props) {
    return (
        <div className="mainApp">
            <div className="topNavHolder">
                <TopNav />
            </div>
            <div className="MainAppHolder">
                <Outlet />
            </div>
            <div className="footerHolder">
                <Footer />
            </div>
        </div>
    );
}

export default MainApp;
