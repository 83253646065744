import React from 'react';
import "./loader.css";

function Loader() {
    return (
        <div className="containerLoader">
            <div className="newtons-cradle">
                <div className="newtons-cradle__dot"></div>
                <div className="newtons-cradle__dot"></div>
                <div className="newtons-cradle__dot"></div>
                <div className="newtons-cradle__dot"></div>
            </div>
        </div>
    );
}

export default Loader;