import React from 'react';
import PropTypes from 'prop-types';
import "./coreValueBox.css";

function CoreValueBox({number, head, description, borderRadius}) {
    return (
        <div className="CoreValueBoxMain" style={{borderRadius: borderRadius}}>
            <div className="CoreValueBoxNumberHolder">
                <div className="CoreValuesBoxNumber">
                    {
                        number
                    }
                </div>
            </div>
            <div className="CoreValueBoxHead">
                {
                    head
                }
            </div>
            <div className="CoreValueBoxDescription">
                {
                    description
                }
            </div>
        </div>
    );
}

CoreValueBox.propTypes = {
    number: PropTypes.number.isRequired,
    head: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    borderRadius: PropTypes.string.isRequired
};

export default CoreValueBox;
