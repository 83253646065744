import React from 'react';
import PropTypes from 'prop-types';
import "./serviceBoxHome.css";


function ServiceBoxHome({icon, description, head}) {
    return (
        <div className="ServiceBoxHomeMain">
            <div className="ServiceBoxHomeIcon">
                <span className="material-symbols-outlined">
                    {
                        icon
                    }
                </span>
            </div>
            <div className="ServiceBoxHomeHeadHolder">
                <h4>{head}</h4>
            </div>
            <div className="ServiceBoxHomeDescription">
                {description}
            </div>
        </div>
    );
}

ServiceBoxHome.propTypes = {
    icon: PropTypes.string,
    description: PropTypes.string,
    head: PropTypes.string
};

export default ServiceBoxHome;