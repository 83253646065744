import React from 'react';
import "./projects.css";

function Projects() {
    return (
        <div className="projectsMain">
            <div className="projectArt" />
            <div className="projectsContentHolder">
                <div className="projectsContentTextSide">
                    <div className="projectHeadThin">
                        See Our Work
                    </div>
                    <div className="projectHeadMega">
                        <span className="Bolderprojecthead">Explore Our Success Stories</span>
                        <span className="Normalprojecthead"> Proof of Our Dedication!</span>
                    </div>
                    <div className="ViewprojectsHolder">
                        <button className="ViewprojectsAction">
                            See Us In Action
                        </button>
                    </div>
                </div>
                <div className="projectContentImagesSide">
                    <div className="projectImageOneContHolder" style={{backgroundImage: "url('https://openismila.s3.amazonaws.com/walls-site/test-image1.jpg')"}}>
                        <div className="projectImageName" style={{left: "-100px", borderBottomRightRadius: "0px", top: "20px"}}>Image Title One</div>
                    </div>
                    <div className="projectImageTwoContHolder" style={{backgroundImage: "url('https://openismila.s3.amazonaws.com/walls-site/test-image2.jpg')"}}>
                        <div className="projectImageName" style={{right: "-30px", borderTopLeftRadius: "0px", bottom: "10px"}}>Image Title Two</div>
                    </div>
                    <div className="projectImageThreeContHolder" style={{backgroundImage: "url('https://openismila.s3.amazonaws.com/walls-site/test-image3.jpg')"}}>
                        <div className="projectImageName" style={{left: "-30px", borderTopLeftRadius: "0px", bottom: "0px"}}>Image Title Three</div>
                    </div>
                    <div className="projectImagefourContHolder" style={{backgroundImage: "url('https://openismila.s3.amazonaws.com/walls-site/test-image4.jpg')"}}>
                        <div className="projectImageName" style={{left: "-10px", borderBottomRightRadius: "0px", top: "0px"}}>Image Title four</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Projects;
