import React, {useState} from 'react';
import "./teamSection.css";
import TeamPassportBox from '../TeamPassportBox/TeamPassportBox';
import Inquiry from '../../../components/Iquiry/Inquiry';

function Teamsection() {
    const [popup, setPopup] = useState(false);
    return (
        <div className="TeamsectionMain">
            <div className="TeamSectionTextSide">
                <div className="teamSectionThinHead">
                    Meet Our Team
                </div>
                <div className="teamSectionBolderHead">
                    The People Behind Our Success
                </div>
                <div className="teamSectionThinMega">
                    Experts Dedicated to Your Growth!
                </div>
                <div className="teamSectionParagraphs">
                    Our team is the heart of what we do. Each member brings passion, expertise, and innovation to help you achieve the best results. Get to know the faces working tirelessly to ensure your success and see how we can work together.
                </div>
                <div className="contactUsTeamSection">
                    <button className="teamSectionInquries" type="button" onClick={()=> setPopup(true)}>
                        Let Us Help You
                    </button>
                    <div className="teamSectionPhoneNumber">
                        <div className="IconHolder">
                            <span className="material-symbols-outlined">call</span>
                        </div>
                        <div className="phoneContHolder">
                            <div className="phoneClassfication">
                                Office Phone
                            </div>
                            <div className="phoneNumber">
                                +255 763 175 801
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="TeamSectionMemberSide">
                <div className="teamPassportsHolder">
                    <div className="teamPassportPositioner posManager1">
                        <TeamPassportBox
                            name="Team Member1"
                            rank="Rank1"
                            image="https://openismila.s3.amazonaws.com/walls-site/manager3.webp"
                        />
                    </div>
                    <div className="teamPassportPositioner posManager2">
                        <TeamPassportBox
                            name="Team Member2"
                            rank="Rank2"
                            image="https://openismila.s3.amazonaws.com/walls-site/manager4.webp"
                        />
                    </div>
                    <div className="teamPassportPositioner posManager3">
                        <TeamPassportBox
                            name="Team Member3"
                            rank="Rank3"
                            image="https://openismila.s3.amazonaws.com/walls-site/manager5.webp"
                        />
                    </div>
                </div>
            </div>
            <div className="popupAnyPageHolder" style={ !popup ? {display: "none"} : {}}>
                <button className="popupCloseButton" onClick={()=> setPopup(false)}>
                    <span className="material-symbols-outlined">close</span>
                </button>
                <Inquiry/>
            </div>
        </div>
    );
}

export default Teamsection;
