import React from 'react';
import "./aboutUs.css";
import Teamsection from './TeamSection/Teamsection';

function AboutUs() {
    return (
        <div className="AboutUsMain">
            <Teamsection/>
            <div className="aboutUsTextOneHolder">
                <h1 className="aboutUsMainHeader">About Us</h1>
                <p className="aboutUsTextParagraph">
                    At Walls International Limited, we are dedicated to delivering exceptional maritime and logistics services with a focus on safety, reliability, and technological innovation. With years of experience and a team of experts, we are proud to serve as a trusted partner in the shipping and cargo industries, offering a comprehensive range of services tailored to meet the unique needs of our clients.
                </p>
            </div>
            <div className="missionTextSection">
                <h1 className="aboutUsMainHeader">Our Mission</h1>
                <p className="missionParagraph">
                    Our mission is to provide top-quality maritime services while ensuring the safety of our clients' cargo, the efficiency of their operations, and adherence to all international and local regulations. We strive to continuously enhance our methods, leveraging the latest technologies to improve the quality and reliability of our services.
                </p>
            </div>
            <div className="whyChooseUs">
                <h1 className="aboutUsMainHeader">Why Choose Us?</h1>
                <p className="whyChooseUsPara">We leverage cutting-edge technology to enhance and document our MPS activities and tallying services through custom software applications that provide real-time operational progress reports. For mechanical services, we utilize the advanced BOSCH KTS 560 ESI Tronic software, the leading mechatronics solution for diagnosing and maintaining heavy-duty machinery. With our extensive experience in port operations and a team of highly trained experts in key operational areas, including cargo tallying and inspection, we ensure exceptional service quality. Additionally, our services are offered at highly competitive prices, helping you reduce operational costs while receiving top-tier solutions.</p>
            </div>
        </div>
    );
}

export default AboutUs;
