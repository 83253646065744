import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import './topNav.css';
import logo from "../../Images/walls_logo.png";

function TopNav() {
    const navigate = useNavigate();
    const getPageName = ()=>{
        const str = window.location.href.split("/")
        const part = str[str.length - 1]
        console.log(part);
        
        if (part.toLowerCase() === "services") {
            return "services"
        } else if (part.toLowerCase() === "about") {
            return "about"
        }
        return ""
    }
    const [currentPage, setCurrentPage] = useState(getPageName())
    
    const goToLink = (linkx) =>{
        setCurrentPage(linkx);
        navigate("/"+linkx)
    }

    return (
        <div className="topNavMain">
            <div className="topNavBand">
                <div className="topNavLogoSection">
                    <img src={logo}  alt="company logo" className="topNavLogoImg" />
                    <div className="companyNameTopNav dancing-script-normal">Walls International</div>
                    <div className="topNavLinksSections">
                        <button className="topNavButtonActs" style={currentPage === "" ? {borderBottom: "#03c70e 2px solid"}: {}} onClick={()=>goToLink("")}>
                            Home
                        </button>
                        <button className="topNavButtonActs" style={currentPage === "services" ? {borderBottom: "#03c70e 2px solid"}: {}} onClick={()=>goToLink("services")}>
                            Our Services
                        </button>
                        <button className="topNavButtonActs" style={currentPage === "about" ? {borderBottom: "#03c70e 2px solid"}: {}} onClick={()=>goToLink("about")}>
                            About Us
                        </button>
                    </div>
                </div>
                
                <div className="topNavClientActionSection">
                    <button className="clientLoginActionCallTopNav">
                        Client Area
                    </button>
                </div>
            </div>
        </div>
    );
}

export default TopNav;
