import React from 'react';
import './App.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import MainApp from './Main/MainApp';
import ErrorPage from './Errors/ErrorPage';
import HomePage from './pages/HomePage/HomePage';
import Services from './pages/Services/Services';
import AboutUs from './pages/About/AboutUs';

function App() {
  const router = createBrowserRouter([{
    path: "/",
    element: <MainApp/>,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: <HomePage/>
      },
      {
        path: "services",
        element: <Services />
      },
      {
        path: "about",
        element: <AboutUs />
      }
    ],
  }])
  return (
   <RouterProvider router={router} />
  );
}

export default App;
